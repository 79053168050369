export const formatAsNumber = (text) => {
    // Step 1: Remove all characters except digits, minus signs, dots, and commas
    let sanitized_text = (text + '').replace(/[^0-9\-,.]/g, '');

    // Step 2: Ensure minus sign is only at the beginning
    if (sanitized_text.indexOf('-') > 0) {
        sanitized_text = sanitized_text.replace(/-/g, ''); // Remove all minus signs
    }
    // Keep the minus sign only if it's the first character
    sanitized_text = sanitized_text.startsWith('-')
        ? '-' + sanitized_text.slice(1).replace(/-/g, '')
        : sanitized_text.replace(/-/g, '');

    // Step 3: Replace commas with dots
    sanitized_text = sanitized_text.replace(',', '.');

    // Step 4: If there are multiple dots, keep only the first one
    const parts = sanitized_text.split('.');
    if (parts.length > 2) {
        sanitized_text = parts[0] + '.' + parts.slice(1).join('');
    }

    // Step 5: Remove leading zeros (but keep them if they are before a dot)
    if (sanitized_text.startsWith('-')) {
        const negativePart = sanitized_text.slice(1);
        sanitized_text = '-' + negativePart.replace(/^0+(?=\d)/, '');
    } else {
        sanitized_text = sanitized_text.replace(/^0+(?=\d)/, '');
    }

    return sanitized_text;
};

export const formatAsPostalCode = (text) => {
    // Remove all characters except digits and dashes
    let sanitized_text = (text + '').replace(/[^0-9-]/g, '');

    // Remove dashes if there are any, and then insert a dash if it's a valid postal code format
    sanitized_text = sanitized_text.replace(/-/g, '');

    // Format postal code according to a common format
    // Assuming the postal code format is 5 digits, optionally followed by a dash and 4 digits (e.g., "12345-6789")
    if (sanitized_text.length > 5) {
        sanitized_text = sanitized_text.slice(0, 5)/* + '-' + sanitized_text.slice(5, 9)*/;
    }

    return sanitized_text;
};

export const formatAsPhoneNumber = (text) => {
    // Step 1: Remove all characters except digits, spaces, and the plus sign
    let sanitized_text = text.replace(/[^0-9+ ]/g, '');

    // Step 2: Handle international prefix
    // If there's a plus sign, make sure it's at the beginning of the string
    if (sanitized_text.indexOf('+') > 0) {
        sanitized_text = sanitized_text.replace(/\+/g, ''); // Remove all plus signs
    }
    // Keep the plus sign only if it's the first character
    sanitized_text = sanitized_text.startsWith('+')
        ? '+' + sanitized_text.slice(1).replace(/\+/g, '')
        : sanitized_text.replace(/\+/g, '');

    // Step 3: Remove all spaces for now
    sanitized_text = sanitized_text.replace(/\s+/g, '');

    // Step 4: Apply formatting rules
    if (sanitized_text.startsWith('+')) {
        // Format international numbers
        if (sanitized_text.startsWith('+33')) {
            // French international format (e.g., +33 6 12 34 56 78)
            sanitized_text = sanitized_text.slice(3); // Remove the +33
            sanitized_text = sanitized_text.replace(/(\d{2})(?=\d)/g, '$1 ').trim(); // Add spaces every two digits
            sanitized_text = '+33 ' + sanitized_text; // Add +33 back
        } else {
            // For other EU numbers, group the digits in a readable way, similar to French formatting
            sanitized_text = sanitized_text.replace(/(\d{3})(?=\d)/g, '$1 ').trim(); // Add spaces every three digits
        }
    } else {
        // Format French domestic numbers (e.g., 06 12 34 56 78)
        if (sanitized_text.length === 10) {
            sanitized_text = sanitized_text.replace(/(\d{2})(?=\d)/g, '$1 ').trim(); // Add spaces every two digits
        } else {
            // For shorter or malformed numbers, just return as is without spaces
            return sanitized_text;
        }
    }

    return sanitized_text;
};
